import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
  };

  return (
    <div className="w-64 min-h-screen overflow-scroll shadow-md bg-white px-1">
      <div className="p-4 flex items-center justify-center">
        <img src={logo} alt="Logo" className="h-16 w-16" />
      </div>
      <h2 className="text-2xl font-semibold text-center text-red-500 pb-4">Growwincapital</h2>
      <nav>
        <NavLink
          to="/"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Dashboard
        </NavLink>

        <NavLink
          to="/users"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Users List
        </NavLink>

         <NavLink
          to="/teamDetails"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Team Details
        </NavLink>

         <NavLink
          to="/teamincome"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Team Income
        </NavLink>

         <NavLink
          to="/income"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Self Income
        </NavLink>

         <NavLink
          to="/referal"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Referal Income
        </NavLink>

         <NavLink
          to="/transferDetails"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Transfer Details
        </NavLink>

         <NavLink
          to="/wallet"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          User Wallet
        </NavLink>

        <NavLink
          to="/invest"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Invested Amount
        </NavLink>

        <NavLink
          to="/investHistory"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Investment History
        </NavLink>

         <NavLink
          to="/deposit"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Deposit Details
        </NavLink>



        <NavLink
          to="/withdraw"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Withdraw Requests
        </NavLink>
       
       
       
        <NavLink
          to="/banner"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Banner Placement
        </NavLink>

        <NavLink
          to="/kyc"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          User KYC
        </NavLink>


         <NavLink
          to="/withdrawData"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Withdraw Data
        </NavLink>

        <NavLink
          to="/platinumData"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Platinum Data
        </NavLink>
        <NavLink
          to="/ticket"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-red-500 text-white" : "")
          }
        >
          Ticket
        </NavLink>
       
       
       
        
       
        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="block py-2.5 text-blue px-4 rounded transition duration-200"
        >
          Logout
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
