
// // export default Ticket;
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";

// const Ticket: React.FC = () => {
//   const [tickets, setTickets] = useState<any[]>([]);
//   const [selectedTicket, setSelectedTicket] = useState<any>(null);
//   const [conversation, setConversation] = useState<any[]>([]);
//   const [newMessage, setNewMessage] = useState<string>("");
//   const [loading, setLoading] = useState(false);
//   const [imageURLs, setImageURLs] = useState<{ [key: string]: string }>({});
//   const conversationEndRef = useRef<HTMLDivElement | null>(null);

//   // Fetch all tickets on component mount
//   useEffect(() => {
//     const fetchTickets = async () => {
//       try {
//         const response = await axios.get("https://api.growwincapital.com/api/adminTicket/tickets/all");
//         setTickets(response.data.tickets);
//       } catch (error) {
//         console.error("Error fetching tickets:", error);
//       }
//     };
//     fetchTickets();
//   }, []);

//   // Fetch conversation for the selected ticket
//   useEffect(() => {
//     if (selectedTicket) {
//       fetchConversation(selectedTicket._id);
//     }
//   }, [selectedTicket]);

//   const fetchConversation = async (ticketId: string) => {
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `https://api.growwincapital.com/api/adminTicket/tickets/${ticketId}/conversation`
//       );
//       setConversation(response.data.conversation.messages);
//       await loadImages(response.data.conversation.messages);
//     } catch (error) {
//       console.error("Error fetching conversation:", error);
//     } finally {
//       setLoading(false);
//       scrollToBottom();
//     }
//   };

//   const scrollToBottom = () => {
//     conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   const handleSelectTicket = (ticket: any) => {
//     setSelectedTicket(ticket);
//     setNewMessage("");
//   };

//   const handleSendMessage = async () => {
//     if (!newMessage.trim()) return;
//     try {
//       await axios.post(
//         `https://api.growwincapital.com/api/adminTicket/tickets/${selectedTicket._id}/reply`,
//         { message: newMessage }
//       );
//       setNewMessage("");
//       fetchConversation(selectedTicket._id);
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//   };

//   const handleCloseTicket = async () => {
//     if (!selectedTicket) return;
//     try {
//       await axios.post(`https://api.growwincapital.com/api/adminTicket/tickets/${selectedTicket._id}/close`);
//       const updatedTicket = { ...selectedTicket, status: "Closed" };
//       setSelectedTicket(updatedTicket);
//       setTickets((prevTickets) =>
//         prevTickets.map((ticket) =>
//           ticket._id === updatedTicket._id ? updatedTicket : ticket
//         )
//       );
//     } catch (error) {
//       console.error("Error closing ticket:", error);
//     }
//   };

//   // Fetch image with authorization token
//   const fetchImage = async (fileName: string): Promise<string | undefined> => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.get(`https://api.growwincapital.com/api/adminTicket/images/${fileName}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         responseType: "blob",
//       });
//       return URL.createObjectURL(response.data);
//     } catch (error) {
//       console.error("Error loading image:", error);
//     }
//   };

//   const loadImages = async (messages: any[]) => {
//     const imagePromises = messages.flatMap((msg) =>
//       msg.attachments ? msg.attachments.map(async (attachment: any) => {
//         const imageUrl = await fetchImage(attachment.filename);
//         if (imageUrl) {
//           setImageURLs((prev) => ({ ...prev, [attachment.filename]: imageUrl }));
//         }
//       }) : []
//     );
//     await Promise.all(imagePromises);
//   };

//   return (
//     <div className="flex h-screen">
//       {/* Ticket List Sidebar */}
//       <div className="w-1/3 border-r border-gray-300 overflow-y-auto">
//         <h2 className="text-lg font-bold p-4">Tickets</h2>
//         {tickets.map((ticket) => (
//           <div
//             key={ticket._id}
//             onClick={() => handleSelectTicket(ticket)}
//             className={`p-4 cursor-pointer ${
//               selectedTicket?._id === ticket._id ? "bg-gray-200" : "hover:bg-gray-100"
//             }`}
//           >
//             <h3 className="font-semibold">{ticket.userName}</h3>
//             <p className="text-sm text-gray-600">{ticket.subject}</p>
//             <p className="text-xs text-gray-500">Status: {ticket.status}</p>
//           </div>
//         ))}
//       </div>

//       {/* Chat Screen */}
//       <div className="w-2/3 flex flex-col">
//         {selectedTicket ? (
//           <>
//             <div className="flex justify-between p-4 border-b border-gray-300">
//               <div>
//                 <h2 className="font-semibold text-lg">{selectedTicket.subject}</h2>
//                 <p className="text-sm text-gray-600">{selectedTicket.userEmail}</p>
//               </div>
//               {selectedTicket.status !== "Closed" ? (
//                 <button onClick={handleCloseTicket} className="bg-red-500 text-white px-4 py-2 rounded">
//                   Close Ticket
//                 </button>
//               ) : (
//                 <p className="text-red-500 font-semibold">Status: Closed</p>
//               )}
//             </div>
//             <div className="flex-1 overflow-y-auto p-4">
//               {loading ? (
//                 <p>Loading conversation...</p>
//               ) : (
//                 conversation.map((msg) => (
//                   <div key={msg._id} className={`flex ${msg.sender === "admin" ? "justify-end" : "justify-start"} mb-4`}>
//                     <div className="max-w-xs bg-gray-200 p-3 rounded-md shadow-sm">
//                       <p className={`font-semibold ${msg.sender === "admin" ? "text-blue-500" : "text-green-500"}`}>
//                         {msg.sender === "admin" ? "Admin" : "User"}
//                       </p>
//                       <p className="text-sm">{msg.message}</p>
//                       {msg.attachments && msg.attachments.length > 0 && (
//                         <div className="mt-2">
//                           {msg.attachments.map((attachment: any) => (
//                             <img
//                               key={attachment._id}
//                               src={imageURLs[attachment.filename] || ""}
//                               alt={attachment.filename}
//                               className="w-32 h-32 object-cover cursor-pointer"
//                               onClick={() => window.open(imageURLs[attachment.filename], "_blank")}
//                             />
//                           ))}
//                         </div>
//                       )}
//                       <span className="text-xs text-gray-500">{new Date(msg.timestamp).toLocaleString()}</span>
//                     </div>
//                   </div>
//                 ))
//               )}
//               <div ref={conversationEndRef} />
//             </div>
//             {!selectedTicket.isClosed && selectedTicket.status !== "Closed" && (
//               <div className="p-4 border-t border-gray-300 flex items-center">
//                 <input
//                   type="text"
//                   value={newMessage}
//                   onChange={(e) => setNewMessage(e.target.value)}
//                   className="flex-1 p-2 border rounded-md mr-2"
//                   placeholder="Type your message..."
//                 />
//                 <button
//                   onClick={handleSendMessage}
//                   className="bg-blue-500 text-white px-4 py-2 rounded-md"
//                 >
//                   Send
//                 </button>
//               </div>
//             )}
//           </>
//         ) : (
//           <div className="flex items-center justify-center h-full">
//             <p className="text-gray-500">Select a ticket to view the conversation</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Ticket;


import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const Ticket: React.FC = () => {
  const [tickets, setTickets] = useState<any[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [conversation, setConversation] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [imageURLs, setImageURLs] = useState<{ [key: string]: string }>({});
  const conversationEndRef = useRef<HTMLDivElement | null>(null);

  // Fetch all tickets on component mount
  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      const response = await axios.get("https://api.growwincapital.com/api/adminTicket/tickets/all");
      setTickets(response.data.tickets);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  // Fetch conversation for the selected ticket
  useEffect(() => {
    if (selectedTicket) {
      fetchConversation(selectedTicket._id);
    }
  }, [selectedTicket]);

  const fetchConversation = async (ticketId: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.growwincapital.com/api/adminTicket/tickets/${ticketId}/conversation`
      );
      setConversation(response.data.conversation.messages);
      await loadImages(response.data.conversation.messages);
    } catch (error) {
      console.error("Error fetching conversation:", error);
    } finally {
      setLoading(false);
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSelectTicket = (ticket: any) => {
    setSelectedTicket(ticket);
    setNewMessage("");
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    try {
      await axios.post(
        `https://api.growwincapital.com/api/adminTicket/tickets/${selectedTicket._id}/reply`,
        { message: newMessage }
      );
      setNewMessage("");
      fetchConversation(selectedTicket._id);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleCloseTicket = async () => {
    if (!selectedTicket) return;
    try {
      await axios.post(`https://api.growwincapital.com/api/adminTicket/tickets/${selectedTicket._id}/close`);
      
      // Update ticket status locally
      const updatedTicket = { ...selectedTicket, status: "Closed" };
      setSelectedTicket(updatedTicket);
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket._id === updatedTicket._id ? updatedTicket : ticket
        )
      );

      // Refetch tickets from server to ensure data consistency on reload
      await fetchTickets();

    } catch (error) {
      console.error("Error closing ticket:", error);
    }
  };

  // Fetch image with authorization token
  const fetchImage = async (fileName: string): Promise<string | undefined> => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`https://api.growwincapital.com/api/adminTicket/images/${fileName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };

  const loadImages = async (messages: any[]) => {
    const imagePromises = messages.flatMap((msg) =>
      msg.attachments ? msg.attachments.map(async (attachment: any) => {
        const imageUrl = await fetchImage(attachment.filename);
        if (imageUrl) {
          setImageURLs((prev) => ({ ...prev, [attachment.filename]: imageUrl }));
        }
      }) : []
    );
    await Promise.all(imagePromises);
  };

  return (
    <div className="flex h-screen">
      {/* Ticket List Sidebar */}
      <div className="w-1/3 border-r border-gray-300 overflow-y-auto">
        <h2 className="text-lg font-bold p-4">Tickets</h2>
        {tickets.map((ticket) => (
          <div
            key={ticket._id}
            onClick={() => handleSelectTicket(ticket)}
            className={`p-4 cursor-pointer ${
              selectedTicket?._id === ticket._id ? "bg-gray-200" : "hover:bg-gray-100"
            }`}
          >
            <h3 className="font-semibold">{ticket.userName}</h3>
            <p className="text-sm text-gray-600">{ticket.subject}</p>
            <p className="text-xs text-gray-500">Status: {ticket.status}</p>
          </div>
        ))}
      </div>

      {/* Chat Screen */}
      <div className="w-2/3 flex flex-col">
        {selectedTicket ? (
          <>
            <div className="flex justify-between p-4 border-b border-gray-300">
              <div>
                <h2 className="font-semibold text-lg">{selectedTicket.subject}</h2>
                <p className="text-sm text-gray-600">{selectedTicket.userEmail}</p>
              </div>
              {selectedTicket.status !== "closed" ? (
                <button onClick={handleCloseTicket} className="bg-red-500 text-white px-4 py-2 rounded">
                  Close Ticket
                </button>
              ) : (
                <p className="text-red-500 font-semibold">Status: Closed</p>
              )}
            </div>
            <div className="flex-1 overflow-y-auto p-4">
              {loading ? (
                <p>Loading conversation...</p>
              ) : (
                conversation.map((msg) => (
                  <div key={msg._id} className={`flex ${msg.sender === "admin" ? "justify-end" : "justify-start"} mb-4`}>
                    <div className="max-w-xs bg-gray-200 p-3 rounded-md shadow-sm">
                      <p className={`font-semibold ${msg.sender === "admin" ? "text-blue-500" : "text-green-500"}`}>
                        {msg.sender === "admin" ? "Admin" : "User"}
                      </p>
                      <p className="text-sm">{msg.message}</p>
                      {msg.attachments && msg.attachments.length > 0 && (
                        <div className="mt-2">
                          {msg.attachments.map((attachment: any) => (
                            <img
                              key={attachment._id}
                              src={imageURLs[attachment.filename] || ""}
                              alt={attachment.filename}
                              className="w-32 h-32 object-cover cursor-pointer"
                              onClick={() => window.open(imageURLs[attachment.filename], "_blank")}
                            />
                          ))}
                        </div>
                      )}
                      <span className="text-xs text-gray-500">{new Date(msg.timestamp).toLocaleString()}</span>
                    </div>
                  </div>
                ))
              )}
              <div ref={conversationEndRef} />
            </div>
            {!selectedTicket.isClosed && selectedTicket.status !== "closed" && (
              <div className="p-4 border-t border-gray-300 flex items-center">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1 p-2 border rounded-md mr-2"
                  placeholder="Type your message..."
                />
                <button
                  onClick={handleSendMessage}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Send
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500">Select a ticket to view the conversation</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ticket;
