

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// interface KYCData {
//   _id: string;
//   email: string;
//   completionPercentage: number;
//   status: string;
//   createdAt: string;
//   updatedAt: string;
//   aadhaarNumber: string;
// }

// const KYCDataComponent: React.FC = () => {
//   const [kycData, setKYCData] = useState<KYCData[]>([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [showPopup, setShowPopup] = useState(false);
//   const [showPopupv, setShowPopupv] = useState(false);
//   const [selectedKYC, setSelectedKYC] = useState<KYCData | null>(null);
//   const [actionType, setActionType] = useState<"Accept" | "Reject" | null>(null);
//   const [aadhaarFrontImg, setAadhaarFrontImg] = useState<string | null>(null);
//   const [aadhaarBackImg, setAadhaarBackImg] = useState<string | null>(null);

//   // Fetch KYC data and update state
//   const fetchKYCData = async () => {
//     try {
//       const response = await axios.get("https://api.growwincapital.com/api/kyc/processing");
//       setKYCData(response.data.data);
//     } catch (error) {
//       console.error("Error fetching KYC data: ", error);
//     }
//   };

//   useEffect(() => {
//     fetchKYCData(); // Load data on component mount
//   }, []);

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   const filteredKYCData = kycData.filter((item) =>
//     item.email.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleAction = async (item: KYCData, action: "Accept" | "Reject") => {
//     setSelectedKYC(item);
//     setActionType(action);
//     setShowPopup(true);

//     // Fetch Aadhaar images
//     try {
//       const frontResponse = await axios.get(
//         `https://api.growwincapital.com/api/kyc/image/${item.email}/front`
//       );
//       const backResponse = await axios.get(
//         `https://api.growwincapital.com/api/kyc/image/${item.email}/back`
//       );
//       setAadhaarFrontImg(frontResponse.config.url || null);
//       setAadhaarBackImg(backResponse.config.url || null);
//     } catch (error) {
//       console.error("Error fetching Aadhaar images: ", error);
//       setAadhaarFrontImg(null);
//       setAadhaarBackImg(null);
//     }
//   };

//   const handleConfirmAction = async () => {
//     if (!selectedKYC || !actionType) return;

//     const status = actionType === "Accept" ? "verified" : "rejected";

//     try {
//       await axios.patch(`https://api.growwincapital.com/api/kyc/verify/${selectedKYC.email}`, { status });
//       setShowPopup(false);
//       fetchKYCData(); // Fetch updated data after confirming action
//     } catch (error) {
//       console.error("Error updating KYC status: ", error);
//     }
//   };

//   async function handleViewAadhaar(item: KYCData): Promise<void> {
//     setSelectedKYC(item);
//     setShowPopupv(true);
//     // Fetch Aadhaar images
//     try {
//       const frontResponse = await axios.get(
//         `https://api.growwincapital.com/api/kyc/image/${item.email}/front`
//       );
//       const backResponse = await axios.get(
//         `https://api.growwincapital.com/api/kyc/image/${item.email}/back`
//       );
//       setAadhaarFrontImg(frontResponse.config.url || null);
//       setAadhaarBackImg(backResponse.config.url || null);
//     } catch (error) {
//       console.error("Error fetching Aadhaar images: ", error);
//       setAadhaarFrontImg(null);
//       setAadhaarBackImg(null);
//     }
//   }

//   return (
//     <div className="relative">
//       <h1 className="text-2xl font-bold mb-4">KYC Data</h1>
//       <input
//         type="text"
//         placeholder="Search by email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />
//       <div className="h-[690px] overflow-scroll">
//         <table className="w-full">
//           <thead>
//             <tr>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Completion Percentage</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Aadhaar No.</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredKYCData.map((item, index) => (
//               <tr key={item._id}>
//                 <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.email}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.completionPercentage}%</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.status}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.aadhaarNumber || "Nil"}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">
//                   {Number(item.completionPercentage) === 100 ? (
//                    <button
//                    className="bg-blue-500 px-4 py-2 rounded-xl text-white"
//                    onClick={() => handleViewAadhaar(item)}
//                  >
//                    View Aadhaar
//                  </button>
//                   ) : Number(item.completionPercentage) === 50 ? (
//                     <span className="text-red-600 font-semibold">Not Submitted</span>
//                   ) : (
//                     <div className="flex gap-2">
//                       <button
//                         className="bg-green-600 px-4 py-2 rounded-xl text-white"
//                         onClick={() => handleAction(item, "Accept")}
//                       >
//                         Accept
//                       </button>
//                       <button
//                         className="bg-red-400 px-4 py-2 rounded-xl text-white"
//                         onClick={() => handleAction(item, "Reject")}
//                       >
//                         Reject
//                       </button>
//                     </div>
//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {showPopup && selectedKYC && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
//             <h2 className="text-xl font-bold mb-4">{actionType} KYC for {selectedKYC.email}</h2>
//             <div className="flex justify-between mb-4">
//               <div>
//                 <h3 className="font-semibold mb-2">Aadhaar Front</h3>
//                 <img
//                   src={aadhaarFrontImg || "/placeholder-image.jpg"}
//                   alt="Aadhaar Front"
//                   onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
//                   className="w-64 h-40 object-cover"
//                 />
//               </div>
//               <div>
//                 <h3 className="font-semibold mb-2">Aadhaar Back</h3>
//                 <img
//                   src={aadhaarBackImg || "/placeholder-image.jpg"}
//                   alt="Aadhaar Back"
//                   onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
//                   className="w-64 h-40 object-cover"
//                 />
//               </div>
//             </div>
//             <div className="flex justify-end gap-4">
//               <button
//                 className="bg-gray-300 px-4 py-2 rounded-xl"
//                 onClick={() => setShowPopup(false)}
//               >
//                 Cancel
//               </button>
//               <button
//                 className={`px-4 py-2 rounded-xl text-white ${actionType === "Accept" ? "bg-green-600" : "bg-red-400"}`}
//                 onClick={handleConfirmAction}
//               >
//                 Confirm {actionType}
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//       {showPopupv && selectedKYC && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
//             <h2 className="text-xl font-bold mb-4">{selectedKYC.email}</h2>
//             <div className="flex justify-between mb-4">
//               <div>
//                 <h3 className="font-semibold mb-2">Aadhaar Front</h3>
//                 <img
//                   src={aadhaarFrontImg || "/placeholder-image.jpg"}
//                   alt="Aadhaar Front"
//                   onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
//                   className="w-64 h-40 object-cover"
//                 />
//               </div>
//               <div>
//                 <h3 className="font-semibold mb-2">Aadhaar Back</h3>
//                 <img
//                   src={aadhaarBackImg || "/placeholder-image.jpg"}
//                   alt="Aadhaar Back"
//                   onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
//                   className="w-64 h-40 object-cover"
//                 />
//               </div>
//             </div>
//             <div className="flex justify-end gap-4">
//               <button
//                 className="bg-gray-300 px-4 py-2 rounded-xl"
//                 onClick={() => setShowPopupv(false)}
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default KYCDataComponent;
import React, { useState, useEffect } from "react";
import axios from "axios";

interface KYCData {
  _id: string;
  email: string;
  completionPercentage: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  aadhaarNumber: string;
}

const KYCDataComponent: React.FC = () => {
  const [kycData, setKYCData] = useState<KYCData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupv, setShowPopupv] = useState(false);
  const [selectedKYC, setSelectedKYC] = useState<KYCData | null>(null);
  const [actionType, setActionType] = useState<"Accept" | "Reject" | null>(null);
  const [aadhaarFrontImg, setAadhaarFrontImg] = useState<string | null>(null);
  const [aadhaarBackImg, setAadhaarBackImg] = useState<string | null>(null);
  const [loadingImages, setLoadingImages] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState<string | null>(null);

  const fetchKYCData = async () => {
    try {
      const response = await axios.get("https://api.growwincapital.com/api/kyc/processing");
      setKYCData(response.data.data);
    } catch (error) {
      console.error("Error fetching KYC data: ", error);
    }
  };

  useEffect(() => {
    fetchKYCData(); // Load data on component mount
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredKYCData = kycData.filter((item) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAction = async (item: KYCData, action: "Accept" | "Reject") => {
    setSelectedKYC(item);
    setActionType(action);
    setShowPopup(true);
    fetchAadhaarImages(item);
  };

  const fetchAadhaarImages = async (item: KYCData) => {
    setLoadingImages(true);
    try {
      const frontResponse = await axios.get(
        `https://api.growwincapital.com/api/kyc/image/${item.email}/front`
      );
      const backResponse = await axios.get(
        `https://api.growwincapital.com/api/kyc/image/${item.email}/back`
      );
      setAadhaarFrontImg(frontResponse.config.url || null);
      setAadhaarBackImg(backResponse.config.url || null);
    } catch (error) {
      console.error("Error fetching Aadhaar images: ", error);
      setAadhaarFrontImg(null);
      setAadhaarBackImg(null);
    } finally {
      setLoadingImages(false);
    }
  };

  const handleConfirmAction = async () => {
    if (!selectedKYC || !actionType) return;
    const status = actionType === "Accept" ? "verified" : "rejected";

    try {
      await axios.patch(`https://api.growwincapital.com/api/kyc/verify/${selectedKYC.email}`, { status });
      setShowPopup(false);
      fetchKYCData(); // Fetch updated data after confirming action
    } catch (error) {
      console.error("Error updating KYC status: ", error);
    }
  };

  const handleViewAadhaar = (item: KYCData) => {
    setSelectedKYC(item);
    setShowPopupv(true);
    fetchAadhaarImages(item);
  };

  const openFullScreenImage = (imgSrc: string | null) => {
    if (imgSrc) setFullScreenImage(imgSrc);
  };

  return (
    <div className="relative">
      <h1 className="text-2xl font-bold mb-4">KYC Data</h1>
      <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <div className="h-[690px] overflow-scroll">
        <table className="w-full">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Completion Percentage</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Aadhaar No.</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredKYCData.map((item, index) => (
              <tr key={item._id}>
                <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.email}</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.completionPercentage}%</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.status}</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.aadhaarNumber || "Nil"}</td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {Number(item.completionPercentage) === 100 ? (
                   <button
                   className="bg-blue-500 px-4 py-2 rounded-xl text-white"
                   onClick={() => handleViewAadhaar(item)}
                 >
                   View Aadhaar
                 </button>
                  ) : Number(item.completionPercentage) === 50 ? (
                    <span className="text-red-600 font-semibold">Not Submitted</span>
                  ) : (
                    <div className="flex gap-2">
                      <button
                        className="bg-green-600 px-4 py-2 rounded-xl text-white"
                        onClick={() => handleAction(item, "Accept")}
                      >
                        Accept
                      </button>
                      <button
                        className="bg-red-400 px-4 py-2 rounded-xl text-white"
                        onClick={() => handleAction(item, "Reject")}
                      >
                        Reject
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Action Popup */}
      {showPopup && selectedKYC && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
            <h2 className="text-xl font-bold mb-4">{actionType} KYC for {selectedKYC.email}</h2>
            {loadingImages ? (
              <div className="flex justify-center items-center h-40">Loading images...</div>
            ) : (
              <div className="flex justify-between mb-4">
                <div>
                  <h3 className="font-semibold mb-2">Aadhaar Front</h3>
                  <img
                    src={aadhaarFrontImg || "/placeholder-image.jpg"}
                    alt="Aadhaar Front"
                    onClick={() => openFullScreenImage(aadhaarFrontImg)}
                    onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
                    className="w-64 h-40 object-cover cursor-pointer"
                  />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Aadhaar Back</h3>
                  <img
                    src={aadhaarBackImg || "/placeholder-image.jpg"}
                    alt="Aadhaar Back"
                    onClick={() => openFullScreenImage(aadhaarBackImg)}
                    onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
                    className="w-64 h-40 object-cover cursor-pointer"
                  />
                </div>
              </div>
            )}
            <div className="flex justify-end gap-4">
              <button className="bg-gray-300 px-4 py-2 rounded-xl" onClick={() => setShowPopup(false)}>
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-xl text-white ${actionType === "Accept" ? "bg-green-600" : "bg-red-400"}`}
                onClick={handleConfirmAction}
              >
                Confirm {actionType}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* View Aadhaar Popup */}
      {showPopupv && selectedKYC && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
            <h2 className="text-xl font-bold mb-4">{selectedKYC.email}</h2>
            {loadingImages ? (
              <div className="flex justify-center items-center h-40">Loading images...</div>
            ) : (
              <div className="flex justify-between mb-4">
                <div>
                  <h3 className="font-semibold mb-2">Aadhaar Front</h3>
                  <img
                    src={aadhaarFrontImg || "/placeholder-image.jpg"}
                    alt="Aadhaar Front"
                    onClick={() => openFullScreenImage(aadhaarFrontImg)}
                    onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
                    className="w-64 h-40 object-cover cursor-pointer"
                  />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Aadhaar Back</h3>
                  <img
                    src={aadhaarBackImg || "/placeholder-image.jpg"}
                    alt="Aadhaar Back"
                    onClick={() => openFullScreenImage(aadhaarBackImg)}
                    onError={(e) => e.currentTarget.src = "/placeholder-image.jpg"}
                    className="w-64 h-40 object-cover cursor-pointer"
                  />
                </div>
              </div>
            )}
            <div className="flex justify-end gap-4">
              <button className="bg-gray-300 px-4 py-2 rounded-xl" onClick={() => setShowPopupv(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Full-Screen Image Modal */}
      {fullScreenImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
          <img
            src={fullScreenImage}
            alt="Full Screen Aadhaar"
            onClick={() => setFullScreenImage(null)}
            className="max-w-full max-h-full cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};

export default KYCDataComponent;
