// import React, { useState, useEffect } from "react";
// import axios from "axios";

// interface WithdrawalData {
//   _id: string;
//   fullName: string;
//   email: string;
//   mobileNumber: string;
//   referralName: string;
//   referralMail: string;
//   joiningDate: string;
//   investmentDate: string;
//   totalAmount: number;
//   withdrawalAmount: number;
//   subscriptionPlan: string;
//   status: string;
//   createdAt: string;
//   updatedAt: string;
// }

// const WithdrawalDataComponent: React.FC = () => {
//   const [withdrawalData, setWithdrawalData] = useState<WithdrawalData[]>([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [showPopup, setShowPopup] = useState(false);
//   const [selectedWithdrawal, setSelectedWithdrawal] = useState<WithdrawalData | null>(null);
//   const [actionType, setActionType] = useState<"Accept" | "Reject" | null>(null);

//   useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     axios
//       .get("https://api.growwincapital.com/api/investmentwithdrawl/withdrawals", { headers })
//       .then((response) => {
//         console.log(response.data.data);
//         setWithdrawalData(response.data.data);
//       })
//       .catch((error) => {
//         console.log("Error fetching withdrawal data: " + error);
//       });
//   }, []);

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   const filteredWithdrawalData = withdrawalData.filter((item) =>
//     item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     item.fullName.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleAction = (item: WithdrawalData, action: "Accept" | "Reject") => {
//     setSelectedWithdrawal(item);
//     setActionType(action);
//     setShowPopup(true);
//   };

//   const handleConfirmAction = () => {
//     // Implement the logic for accepting or rejecting the withdrawal
//     console.log(`${actionType} withdrawal for ${selectedWithdrawal?.email}`);
//     setShowPopup(false);
//   };

//   return (
//     <div className="relative">
//       <h1 className="text-2xl font-bold mb-4">Withdrawal Data</h1>
//       <input
//         type="text"
//         placeholder="Search by email or name..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />
//       <div className="h-[690px] overflow-scroll">
//         <table className="w-full">
//           <thead>
//             <tr>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Mobile</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Total Amount</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Withdrawal Amount</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredWithdrawalData.map((item, index) => (
//               <tr key={item._id}>
//                 <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.fullName}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.email}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.mobileNumber}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">${item.totalAmount}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">${item.withdrawalAmount}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{item.status}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">
//                   <div className="flex gap-2">
//                     <button 
//                       className="bg-green-600 px-4 py-2 rounded-xl text-white"
//                       onClick={() => handleAction(item, "Accept")}
//                     >
//                       Accept
//                     </button>
//                     <button 
//                       className="bg-red-400 px-4 py-2 rounded-xl text-white"
//                       onClick={() => handleAction(item, "Reject")}
//                     >
//                       Reject
//                     </button>
//                   </div>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {showPopup && selectedWithdrawal && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
//             <h2 className="text-xl font-bold mb-4">{actionType} Withdrawal for {selectedWithdrawal.fullName}</h2>
//             <div className="mb-4">
//               <p><strong>Email:</strong> {selectedWithdrawal.email}</p>
//               <p><strong>Mobile:</strong> {selectedWithdrawal.mobileNumber}</p>
//               <p><strong>Total Amount:</strong> ${selectedWithdrawal.totalAmount}</p>
//               <p><strong>Withdrawal Amount:</strong> ${selectedWithdrawal.withdrawalAmount}</p>
//               <p><strong>Subscription Plan:</strong> {selectedWithdrawal.subscriptionPlan}</p>
//               <p><strong>Investment Date:</strong> {new Date(selectedWithdrawal.investmentDate).toLocaleDateString()}</p>
//             </div>
//             <div className="flex justify-end gap-4">
//               <button 
//                 className="bg-gray-300 px-4 py-2 rounded-xl"
//                 onClick={() => setShowPopup(false)}
//               >
//                 Cancel
//               </button>
//               <button 
//                 className={`px-4 py-2 rounded-xl text-white ${actionType === "Accept" ? "bg-green-600" : "bg-red-400"}`}
//                 onClick={handleConfirmAction}
//               >
//                 Confirm {actionType}
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default WithdrawalDataComponent;

import React, { useState, useEffect } from "react";
import axios from "axios";

interface WithdrawalData {
  _id: string;
  fullName: string;
  email: string;
  mobileNumber: string;
  referralName: string;
  referralMail: string;
  joiningDate: string;
  investmentDate: string;
  totalAmount: number;
  withdrawalAmount: number;
  subscriptionPlan: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const WithdrawalDataComponent: React.FC = () => {
  const [withdrawalData, setWithdrawalData] = useState<WithdrawalData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState<WithdrawalData | null>(null);
  const [actionType, setActionType] = useState<"Accept" | "Reject" | null>(null);

  const fetchWithdrawals = () => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    axios
      .get("https://api.growwincapital.com/api/investmentwithdrawl/withdrawals", { headers })
      .then((response) => {
        setWithdrawalData(response.data.data);
      })
      .catch((error) => {
        console.log("Error fetching withdrawal data: " + error);
      });
  };

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredWithdrawalData = withdrawalData.filter((item) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAction = (item: WithdrawalData, action: "Accept" | "Reject") => {
    setSelectedWithdrawal(item);
    setActionType(action);
    setShowPopup(true);
  };

  const handleConfirmAction = () => {
    if (!selectedWithdrawal || !actionType) return;

    const newStatus = actionType === "Accept" ? "approved" : "rejected";

    axios
      .patch(`https://api.growwincapital.com/api/investmentwithdrawl/withdrawal/${selectedWithdrawal._id}`, { status: newStatus })
      .then(() => {
        // Update the local state for the modified item
        setWithdrawalData((prevData) =>
          prevData.map((item) =>
            item._id === selectedWithdrawal._id
              ? { ...item, status: newStatus }
              : item
          )
        );
        setShowPopup(false);
        fetchWithdrawals(); // Refresh data
      })
      .catch((error) => {
        console.log("Error updating withdrawal status: " + error);
        setShowPopup(false);
      });
  };

  return (
    <div className="relative">
      <h1 className="text-2xl font-bold mb-4">Withdrawal Data</h1>
      <input
        type="text"
        placeholder="Search by email or name..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <div className="h-[690px] overflow-scroll">
        <table className="w-full">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Mobile</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Total Amount</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Withdrawal Amount</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredWithdrawalData.map((item, index) => (
              <tr key={item._id}>
                <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.fullName}</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.email}</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.mobileNumber}</td>
                <td className="py-3 px-4 border-b border-gray-200">${item.totalAmount}</td>
                <td className="py-3 px-4 border-b border-gray-200">${item.withdrawalAmount}</td>
                <td className="py-3 px-4 border-b border-gray-200">{item.status}</td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {item.status === "processing" ? (
                    <div className="flex gap-2">
                      <button 
                        className="bg-green-600 px-4 py-2 rounded-xl text-white"
                        onClick={() => handleAction(item, "Accept")}
                      >
                        Accept
                      </button>
                      <button 
                        className="bg-red-400 px-4 py-2 rounded-xl text-white"
                        onClick={() => handleAction(item, "Reject")}
                      >
                        Reject
                      </button>
                    </div>
                  ) : (
                    <span className={`px-4 py-2 rounded-xl text-white ${item.status === "approved" ? "bg-green-600" : "bg-red-400"}`}>
                      {item.status === "approved" ? "Accepted" : "Rejected"}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showPopup && selectedWithdrawal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
            <h2 className="text-xl font-bold mb-4">{actionType} Withdrawal for {selectedWithdrawal.fullName}</h2>
            <div className="mb-4">
              <p><strong>Email:</strong> {selectedWithdrawal.email}</p>
              <p><strong>Mobile:</strong> {selectedWithdrawal.mobileNumber}</p>
              <p><strong>Total Amount:</strong> ${selectedWithdrawal.totalAmount}</p>
              <p><strong>Withdrawal Amount:</strong> ${selectedWithdrawal.withdrawalAmount}</p>
              <p><strong>Subscription Plan:</strong> {selectedWithdrawal.subscriptionPlan}</p>
              <p><strong>Investment Date:</strong> {new Date(selectedWithdrawal.investmentDate).toLocaleDateString()}</p>
            </div>
            <div className="flex justify-end gap-4">
              <button 
                className="bg-gray-300 px-4 py-2 rounded-xl"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button 
                className={`px-4 py-2 rounded-xl text-white ${actionType === "Accept" ? "bg-green-600" : "bg-red-400"}`}
                onClick={handleConfirmAction}
              >
                Confirm {actionType}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawalDataComponent;
