import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import Withdraw from './components/Withdraw';
import Users from './components/Users';
import Settings from './components/Settings';
import TeamDetail from './components/TeamDetails';
import AdminDashboard from './components/AdminDashboard';
import TransferDetail from './components/TransferDetails';
import LoginPage from './components/Login';
import Banner from './components/Banner';
import Deposits from './components/Deposit';
import Referal from './components/Referal';
import TeamIncome from './components/TeamIncome';
import Income from './components/Income';
import Invest from './components/Invest';
import Wallet from './components/WalletUpdate';
import InvestmentHistory from './components/InvestHistory';
import KYCDataComponent from './components/KYC';
import WithdrawalDataComponent from './components/WithdrawData';
import Platinum from './components/Platinum';
import Ticket from './components/Ticket';

function App() {
  const token = localStorage.getItem('token');

  const ProtectedRoutes = () => {
    return token ? <Outlet /> : <Navigate to="/login" replace />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route path="deposit" element={<Deposits />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
            <Route path="teamDetails" element={<TeamDetail />} />
            <Route path="transferDetails" element={<TransferDetail />} />
            <Route path="banner" element={<Banner />} />
            <Route path="referal" element={<Referal />} />
            <Route path="teamincome" element={<TeamIncome />} />
            <Route path="income" element={<Income />} />
            <Route path="invest" element={<Invest />} />
            <Route path="investHistory" element={<InvestmentHistory />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="kyc" element={<KYCDataComponent />} />
            <Route path="withdrawData" element={<WithdrawalDataComponent />} />
            <Route path="platinumData" element={<Platinum />} />
            <Route path='/ticket' element={<Ticket/>}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
